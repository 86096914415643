import { DEEPLINKS } from '../constants/deeplinks';

export const goToPortal = ({ navigation, url } = {}) => {
  if (url) {
    navigation?.push(url);
  }

  navigation?.push(DEEPLINKS.PORTAL.HOME);
};

export const getUrlSearchParams = ({ navigation } = {}) => {
  const urlSearchParams = new URLSearchParams(navigation?.location?.search);
  return Object.fromEntries(urlSearchParams.entries());
};

export const getJshellCompatibleUrl = ({ url } = {}) =>
  url?.includes('/ucde/') ? url.replace('/ucde', '') : url;

export const urlShouldRedirectToMyPrintersPage = ({ url } = {}) => {
  if (!url) return false;

  const [country, language] = url.split('/').slice(1, 3) || [];

  if (country?.length !== 2 || language?.length !== 2) return false;

  const locale = `/${country}/${language}`.toLowerCase();

  return [
    DEEPLINKS.LEGACY_PORTAL.ACTIVATE_HP_PLUS,
    DEEPLINKS.LEGACY_PORTAL.MOBILE_FAX,
    DEEPLINKS.LEGACY_PORTAL.PAPER_SUBSCRIPTION
  ].some((link) => `${locale}${link}` === url);
};
