import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import HighchartsInterface from '@veneer/highcharts-interface';
import IconXCircle from '@veneer/core/dist/scripts/icons/icon_x_circle';
import ChartProvider from '@veneer/core/dist/scripts/chart/chart_provider';
import Chart from '@veneer/core/dist/scripts/chart/chart';
import ErrorWidget from '../../ErrorWidget';
import LoaderWidget from '../../LoaderWidget';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { generalTooltip } from '../utils';
import { CustomChart, ErrorContainer, LoaderContainer } from './styles';

const GenericChart = ({
  'data-testid': dataTestId,
  error,
  loading,
  onRetry,
  options,
  tooltip,
  i18n,
}) => {
  const isMobile = useMediaQuery(mediaQueries.xs);

  const chartOptions = useMemo(() => ({
    chart: {
      height: 250,
      events: {
        redraw() {
          const chart = this;
          if (!isMobile && (chart.xAxis[0].min !== 0 || chart.xAxis[0].max !== 11)) {
            chart.xAxis[0].setExtremes(0, 11);
          }
          if (isMobile && (chart.xAxis[0].min === 0 && chart.xAxis[0].max === 11)) {
            chart.xAxis[0].setExtremes(0, 4);
          }
        },
      },
      scrollablePlotArea: {
        scrollPositionX: 0,
      },
    },
    title: {
      text: '',
    },
    legend: {
      squareSymbol: true,
      symbolRadius: 3,
      symbolHeight: 16,
      ...(isMobile && {
        itemMarginTop: 8,
        margin: 4,
      }),
    },
  }), [isMobile]);

  if (error) {
    return (
      <ErrorContainer>
        <ErrorWidget
          message={i18n.error}
          labelRetry={i18n.retry}
          onRetry={onRetry}
          icon={<IconXCircle size={24} color="colorGray7" />}
        />
      </ErrorContainer>
    );
  }

  if (loading) {
    return (
      <LoaderContainer>
        <LoaderWidget fullScreen />
      </LoaderContainer>
    );
  }

  return (
    <ChartProvider chartInterface={HighchartsInterface}>
      <CustomChart data-testid={dataTestId}>
        <Chart
          options={{
            ...chartOptions,
            ...options,
            legend: { ...chartOptions.legend, ...options.legend },
            tooltip: {
              ...generalTooltip,
              ...tooltip,
            },
          }}
        />
      </CustomChart>
    </ChartProvider>
  );
};

GenericChart.propTypes = {
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  'data-testid': PropTypes.string,
  i18n: PropTypes.shape({
    retry: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
  }).isRequired,
  tooltip: PropTypes.shape({
    shared: PropTypes.bool,
    followPointer: PropTypes.bool,
    formatter: PropTypes.func,
  }).isRequired,
};

GenericChart.defaultProps = {
  'data-testid': '',
};

export default GenericChart;
