import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tokens from '@veneer/tokens';
import GenericChart from '../GenericChart';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  getPointSelected,
  getPointsAverage,
  getPointsWithoutAverage,
  getTooltipAverage,
  getTooltipGeneralAverage,
} from '../utils';

const SERIES_IDS = {
  COLOR: 0,
  BLACK_AND_WHITE: 1,
  AVERAGE: 2,
};

const PrintPageChart = ({
  chartData,
  error,
  loading,
  onRetry,
  selectedYear,
  pointStartAverage,
  i18n,
}) => {
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.xs);

  const isDeviceSupportTouch = ('ontouchstart' in window)
                              || (navigator.maxTouchPoints > 0)
                              || (navigator.msMaxTouchPoints > 0);

  const colorsDefault = useMemo(() => ((['#404040', '#8C3EBF', '#FFAC4D'])), []);
  const colorsHighlighted = useMemo(() => ((['#545454', '#B655F5'])), []);

  const legendTitles = useMemo(() => ({
    [SERIES_IDS.AVERAGE]: i18n.legend.average,
    [SERIES_IDS.BLACK_AND_WHITE]: i18n.legend.blackAndWhite,
    [SERIES_IDS.COLOR]: i18n.legend.color,
  }), [
    i18n.legend.average,
    i18n.legend.blackAndWhite,
    i18n.legend.color,
  ]);

  const monthMapping = useMemo(() => i18n.abbrMonths
    .reduce((acc, cur, i) => ({
      ...acc,
      [cur]: i18n.months[i],
    }), {}), [i18n.months, i18n.abbrMonths]);

  const tooltip = useMemo(() => ({
    shared: sharedTooltip,
    followPointer: sharedTooltip,
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      const chart = this;
      let pointSelected = chart.point;

      if (!pointSelected) {
        pointSelected = getPointSelected(chart.points);
      }

      if (!!pointSelected && pointSelected.series.userOptions?.isTooltipAverage === true) {
        return getTooltipAverage(pointSelected);
      }

      const pointsAverage = getPointsAverage(chart?.points);

      return getTooltipGeneralAverage(pointsAverage, isDeviceSupportTouch) + getPointsWithoutAverage(chart.points).reduce((s, point) => `${s}<br/> &nbsp; &nbsp; &#x2022; ${legendTitles[point.series.userOptions.id]}: ${point.y}`,
        `<b>${monthMapping[chart.x]}</b> <br/> ${i18n.total}: ${chart.points[1].total}`);
    },
  }), [
    i18n.total,
    isDeviceSupportTouch,
    legendTitles,
    monthMapping,
    sharedTooltip,
  ]);

  const options = useMemo(() => ({
    plotOptions: {
      column: {
        pointWidth: 16,
      },
      series: {
        stacking: 'normal',
        marker: {
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null, // inherit from series
        },
      },
    },
    yAxis: {
      min: 0,
      max: isMobile ? chartData?.maxAxisY : null,
      title: {
        text: i18n.yAxis,
      },
    },
    xAxis: {
      min: 0,
      max: isMobile ? 4 : 11,
      scrollbar: {
        enabled: isMobile,
      },
      categories: i18n.abbrMonths,
    },
    series: [
      {
        id: SERIES_IDS.AVERAGE,
        legendIndex: 2,
        type: 'line',
        name: i18n.legend.average,
        pointStart: pointStartAverage,
        data: chartData.blackAndWhite.slice(pointStartAverage).map(val => (val === null ? null : chartData.average)),
        color: colorsDefault[2],
        zIndex: 1,
        stickyTracking: false,
        isTooltipAverage: true,
        selectedYear,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      },
      {
        id: SERIES_IDS.COLOR,
        stickyTracking: false,
        legendIndex: 1,
        borderRadiusTopLeft: chartData.average ? tokens.borderRadius2 : 0,
        borderRadiusTopRight: chartData.average ? tokens.borderRadius2 : 0,
        name: i18n.legend.color,
        data: chartData.color,
        type: 'column',
        color: colorsDefault[1],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[1],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[1],
            });
          },
        },
      },
      {
        id: SERIES_IDS.BLACK_AND_WHITE,
        legendIndex: 0,
        borderRadiusTopLeft: 0,
        borderRadiusTopRight: 0,
        name: i18n.legend.blackAndWhite,
        data: chartData.blackAndWhite,
        type: 'column',
        color: colorsDefault[0],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[0],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[0],
            });
          },
        },
      },
    ],
  }), [
    chartData.blackAndWhite,
    chartData.color,
    chartData.average,
    chartData?.maxAxisY,
    colorsDefault,
    colorsHighlighted,
    pointStartAverage,
    selectedYear,
    i18n.abbrMonths,
    i18n.legend.average,
    i18n.legend.blackAndWhite,
    i18n.legend.color,
    i18n.yAxis,
    isMobile,
  ]);

  return (
    <GenericChart
      data-testid="usage-print-page"
      error={error}
      loading={loading}
      onRetry={onRetry}
      options={options}
      tooltip={tooltip}
      i18n={{
        error: i18n.error,
        retry: i18n.retry,
      }}
    />
  );
};

PrintPageChart.defaultProps = {
  pointStartAverage: 0,
};

PrintPageChart.propTypes = {
  chartData: PropTypes.shape({
    blackAndWhite: PropTypes.arrayOf(PropTypes.number).isRequired,
    color: PropTypes.arrayOf(PropTypes.number).isRequired,
    average: PropTypes.number.isRequired,
    maxAxisY: PropTypes.number,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  selectedYear: PropTypes.number.isRequired,
  pointStartAverage: PropTypes.number,
  i18n: PropTypes.shape({
    legend: PropTypes.shape({
      average: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      blackAndWhite: PropTypes.string.isRequired,
    }),
    yAxis: PropTypes.string.isRequired,
    months: PropTypes.arrayOf(PropTypes.string).isRequired,
    abbrMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }).isRequired,
};

export default PrintPageChart;
