import { SQCM_TO_SQM } from './utils';

export const PrintCategory = {
  Blueprint: 'blueprint',
  MonoLines: 'monoLines',
  ColorLines: 'colorLines',
  ColorLowDensityImages: 'colorLowDensityImages',
  ColorHighDensityImages: 'colorHighDensityImages',
  MonoLowDensityImages: 'monoLowDensityImages',
  MonoHighDensityImages: 'monoHighDensityImages',
  Special: 'special',
};

export const UIPrintCategory = {
  Blueprint: 'blueprint',
  MonoLines: 'monoLines',
  ColorLines: 'colorLines',
  LowDensityImage: 'lowDensity',
  HighDensityImage: 'highDensity',
  PremiumQualityImage: 'premium',
};

export const ImpressionsCompletedType = {
  Mono: 'impressionsCompletedMono',
  Color: 'impressionsCompletedColor',
  Total: 'impressionsCompleted',
};

export const FaxType = {
  Sent: 'sentAnalogImages',
  Received: 'receivedAnalogImages',
};

export const getUIPrintCategory = printCategory => {
  switch (printCategory) {
    case PrintCategory.Blueprint:
      return UIPrintCategory.Blueprint;
    case PrintCategory.MonoLines:
      return UIPrintCategory.MonoLines;
    case PrintCategory.ColorLines:
      return UIPrintCategory.ColorLines;
    case PrintCategory.ColorLowDensityImages:
      return UIPrintCategory.LowDensityImage;
    case PrintCategory.ColorHighDensityImages:
      return UIPrintCategory.HighDensityImage;
    case PrintCategory.MonoLowDensityImages:
      return UIPrintCategory.LowDensityImage;
    case PrintCategory.MonoHighDensityImages:
      return UIPrintCategory.HighDensityImage;
    case PrintCategory.Special:
      return UIPrintCategory.PremiumQualityImage;
    default:
      return null;
  }
};

export function getInitialPrintData(numMonths) {
  return {
    blackAndWhite: Array(numMonths).fill(0),
    color: Array(numMonths).fill(0),
    total: 0,
    average: 0,
  };
}

export function getInitialPrintAreaData(numMonths) {
  return Object
    .values(UIPrintCategory)
    .reduce((obj, value) => ({
      ...obj,
      categories: {
        ...obj.categories,
        [value]: Array(numMonths).fill(0),
      },
    }), { categories: {}, average: 0, total: 0 });
}

export function getInitialScanData(numMonths) {
  return {
    monthly: [...Array(numMonths).fill(0)],
    total: 0,
    average: 0,
  };
}

export function getInitialFaxData(numMonths) {
  return {
    sent: {
      monthly: Array(numMonths).fill(0),
      average: 0,
      total: 0,
    },
    received: {
      monthly: Array(numMonths).fill(0),
      average: 0,
      total: 0,
    },
  };
}

const sumArrays = (arr1, arr2) => arr1.map((num, i) => num + arr2[i]);
const fillRestWithNull = monthly => [...monthly, ...Array(12 - monthly.length).fill(null)];

const sumCategoryCounters = (categoryCounters, other) => Object
  .values(UIPrintCategory)
  .reduce((acc, category) => ({
    ...acc,
    [category]: sumArrays(categoryCounters[category], other[category]),
  }), {});

const buildMonthlyCounters = counters => {
  const initialByMonth = Array(12).fill(0);

  const monthlyCounters = counters
    .sort(({ yearMonth }) => yearMonth)
    .reduce((acc, { yearMonth, ...rest }) => {
      const monthIndex = Number(yearMonth.split('-')[1]) - 1;

      return [
        ...acc.slice(0, monthIndex),
        rest ? { yearMonth, ...rest } : null,
        ...acc.slice(monthIndex + 1),
      ];
    }, initialByMonth);

  return monthlyCounters;
};

export const roundCounter = number => parseFloat(number.toFixed(2));

const convertCounters = (counters, unit) => Object
  .keys(counters)
  .reduce((acc, key) => ({
    ...acc,
    [key]: counters[key].map(monthlyCounter => roundCounter(monthlyCounter * unit)),
  }), {});

const getNoDataYet = (hasLastDataReceivedOn, total) => (!hasLastDataReceivedOn && total === 0);

export const buildPrintAreaChartData = ({
  payload,
  numMonths,
  unit = SQCM_TO_SQM,
  numMonthsAverage,
}) => {
  const deviceCounters = payload?.usageByPrintCategory || [];
  let hasLastDataReceivedOn = false;

  const initialPrintAreaData = getInitialPrintAreaData(numMonths);
  const initialCategoryCounters = initialPrintAreaData.categories;

  const summedCounters = deviceCounters?.reduce((categoryCounters, { lastDataReceivedOn, counters }) => {
    hasLastDataReceivedOn = lastDataReceivedOn || hasLastDataReceivedOn;
    const byCategory = counters?.reduce((totalByCategory, { printCategory, finalCounter }) => {
      const uiPrintCategory = getUIPrintCategory(printCategory);

      if (!uiPrintCategory) {
        return totalByCategory;
      }

      const monthlyCounters = buildMonthlyCounters(finalCounter)
        .map(counter => (counter ? counter.usedArea : 0));

      return {
        ...totalByCategory,
        [uiPrintCategory]: sumArrays(totalByCategory[uiPrintCategory], monthlyCounters),
      };
    }, initialCategoryCounters) || initialCategoryCounters;

    return sumCategoryCounters(categoryCounters, byCategory);
  }, initialCategoryCounters) || initialCategoryCounters;

  const convertedCounters = convertCounters(summedCounters, unit);
  const areaTotal = roundCounter(Object
    .values(convertedCounters)
    .reduce((acc, monthlyCounters) => acc + monthlyCounters
      .reduce((monthlyTotal, value) => monthlyTotal + value, 0), 0));

  const categories = Object
    .keys(convertedCounters)
    .reduce((acc, key) => ({
      ...acc,
      [key]: fillRestWithNull(convertedCounters[key]),
    }), {});

  let maxAxisY = 0;
  Object.keys(categories).forEach(key => {
    maxAxisY += Math.max(...categories[key]);
  });

  return {
    categories,
    total: areaTotal,
    average: roundCounter(areaTotal / numMonthsAverage),
    noDataYet: getNoDataYet(hasLastDataReceivedOn, areaTotal),
    maxAxisY,
  };
};

export const buildPrintPagesChartData = ({ payload, numMonths, numMonthsAverage }) => {
  const deviceCounters = payload?.printCounters;
  let hasLastDataReceivedOn = false;

  const zeroedCounters = getInitialPrintData(numMonths);
  const counters = deviceCounters?.reduce((countersByDevice, deviceCounter) => {
    const monthly = buildMonthlyCounters(deviceCounter?.counters || []);

    hasLastDataReceivedOn = deviceCounter?.lastDataReceivedOn || hasLastDataReceivedOn;

    const accMono = countersByDevice.blackAndWhite
      .map((impressionsCompletedMono, i) => impressionsCompletedMono + (monthly[i]?.impressionsCompletedMono || 0));

    const accColor = countersByDevice.color
      .map((impressionsCompletedColor, i) => impressionsCompletedColor + (monthly[i]?.impressionsCompletedColor || 0));

    const accTotal = countersByDevice.total + monthly.reduce((acc, cur) => acc + (cur?.impressionsCompleted || 0), 0);

    return {
      blackAndWhite: accMono,
      color: accColor,
      total: accTotal,
    };
  }, zeroedCounters) || zeroedCounters;

  const maxAxisY = Math.max(...counters.blackAndWhite) + Math.max(...counters.color);

  const data = {
    blackAndWhite: fillRestWithNull(counters.blackAndWhite),
    color: fillRestWithNull(counters.color),
    total: counters.total,
    average: roundCounter(counters.total / numMonthsAverage),
    noDataYet: getNoDataYet(hasLastDataReceivedOn, counters.total),
    maxAxisY,
  };

  return data;
};

export const buildScanChartData = ({ payload, numMonths, numMonthsAverage }) => {
  const deviceCounters = payload?.scanCounters;
  const zeroedCounters = getInitialScanData(numMonths);
  let hasLastDataReceivedOn = false;

  const summedCounters = deviceCounters?.reduce((countersByDevice, deviceCounter) => {
    hasLastDataReceivedOn = deviceCounter?.lastDataReceivedOn || hasLastDataReceivedOn;
    const monthly = buildMonthlyCounters(deviceCounter?.counters || [])
      .map(counter => (counter ? counter.totalImages : 0));

    return { monthly: sumArrays(countersByDevice.monthly, monthly) };
  }, zeroedCounters) || zeroedCounters;

  const total = summedCounters.monthly.reduce((acc, cur) => acc + cur, 0);

  const maxAxisY = Math.max(...summedCounters.monthly);

  const data = {
    monthly: fillRestWithNull(summedCounters.monthly),
    total,
    average: roundCounter(total / numMonthsAverage),
    noDataYet: getNoDataYet(hasLastDataReceivedOn, total),
    maxAxisY,
  };

  return data;
};

export const buildFaxChartData = ({ payload, numMonths, numMonthsAverage }) => {
  const deviceCounters = payload?.faxCounters;
  const zeroedCounters = getInitialFaxData(numMonths);
  let hasLastDataReceivedOn = false;

  const summedCounters = deviceCounters?.reduce((countersByDevice, deviceCounter) => {
    const monthly = buildMonthlyCounters(deviceCounter?.counters || []);
    hasLastDataReceivedOn = deviceCounter?.lastDataReceivedOn || hasLastDataReceivedOn;

    const accReceived = countersByDevice.received.monthly
      .map((received, i) => received + (monthly[i]?.[FaxType.Received] || 0));

    const accSent = countersByDevice.sent.monthly
      .map((sent, i) => sent + (monthly[i]?.[FaxType.Sent] || 0));

    return {
      sent: { monthly: accSent },
      received: { monthly: accReceived },
    };
  }, zeroedCounters) || zeroedCounters;

  const {
    sent,
    received,
  } = summedCounters;

  const totalSent = sent.monthly.reduce((acc, cur) => acc + cur, 0);
  const totalReceived = received.monthly.reduce((acc, cur) => acc + cur, 0);
  const maxAxisY = Math.max(...received.monthly) + Math.max(...sent.monthly);

  const data = {
    sent: {
      monthly: fillRestWithNull(sent.monthly),
      average: roundCounter(totalSent / numMonthsAverage),
      total: totalSent,
    },
    received: {
      monthly: fillRestWithNull(received.monthly),
      average: roundCounter(totalReceived / numMonthsAverage),
      total: totalReceived,
    },
    noDataYet: getNoDataYet(hasLastDataReceivedOn, (totalSent + totalReceived)),
    maxAxisY,
  };

  return data;
};

export const getNumMonthsAverage = ({
  selectedYear,
  createOrgDate,
  currentDate,
}) => {
  const yearCreateOrg = createOrgDate.getFullYear();
  const monthCreateOrg = createOrgDate.getMonth();

  if (yearCreateOrg === selectedYear) {
    if (currentDate.getFullYear() === selectedYear) {
      return (currentDate.getMonth() + 1) - monthCreateOrg;
    }
    return 12 - monthCreateOrg;
  }

  if (currentDate.getFullYear() === selectedYear) {
    return currentDate.getMonth() + 1;
  }

  return 12;
};
