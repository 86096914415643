import React from 'react';
import projectNames from 'src/configs/projectNames';
import resources from '../src/assets/locale';
import { RootProvider } from '@jarvis/react-portal-addons';
import { App } from 'src';

export default function Root(props) {
  const shell = window.Shell;
  const { stack } = props;

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <RootProvider
        localization={shell.v1.localization}
        resources={resources}
        stack={stack}
        shell={shell}
      >
        <App {...props} />
      </RootProvider>
    </section>
  );
}
