export const DEEPLINKS = {
  PORTAL: {
    HOME: '/',
    MY_PRINTERS: '/my-printers'
  },
  LEGACY_PORTAL: {
    GET_SOFTWARE: '/get-software',
    ACTIVATE_HP_PLUS: '/activate-hp-plus',
    MOBILE_FAX: '/mobile-fax',
    PAPER_SUBSCRIPTION: '/paper-subscription'
  }
};
