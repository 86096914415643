import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client';

export async function userTenantApiCall({ authProvider, stack }) {
  try {
    const client = new UserTenantMgtSvcClient(stack, authProvider);
    const response = await client.getCurrentActiveUserWithTenant();
    return response.data;
  } catch (e) {
    console.error(e);
    return {};
  }
}
