import React from 'react';
import { Buffer } from 'buffer';
import UserOnboarding from '../components/UserOnboarding';

const App = (props) => {
  if (!window.Buffer) {
    window.Buffer = Buffer;
  }
  const mfeProperties = props?.properties || {};
  return <UserOnboarding mfeProperties={mfeProperties} />;
};

export default App;
