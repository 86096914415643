import React from 'react';
import { Container, ActionButton } from './styles';
import { useRootContext } from '@jarvis/react-portal-addons';

const ActionButtons = ({
  actionLabel,
  actionCallback,
  dismissLabel,
  dismissCallback,
  removeModal
}) => {
  const {
    shell: {
      v1: { navigation }
    }
  } = useRootContext();

  const navigateToBackPage = () => {
    navigation.goBack();
  };

  const closeModal = () => {
    if (removeModal) {
      removeModal();
    }
  };

  const handleAction = () => {
    if (actionCallback) actionCallback();
    closeModal();
  };

  const handleDismiss = () => {
    dismissCallback ? dismissCallback() : navigateToBackPage();
    closeModal();
  };

  if (actionLabel) {
    return (
      <Container data-testid="action-buttons-container">
        <ActionButton
          aria-label={actionLabel}
          onClick={handleAction}
          data-testid="action-label-button"
        >
          {actionLabel}
        </ActionButton>
        {dismissLabel && (
          <ActionButton
            appearance="ghost"
            aria-label={dismissLabel}
            onClick={handleDismiss}
            btntype="dismiss"
            data-testid="dismiss-label-button"
          >
            {dismissLabel}
          </ActionButton>
        )}
      </Container>
    );
  }

  return null;
};

export default ActionButtons;
