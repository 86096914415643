export const getCookie = (cookieName) => {
  const cookies = (document.cookie || '').split(';');
  const encodedCookieName = encodeURIComponent(cookieName);
  for (let i = 0; i < cookies.length; i += 1) {
    const [name, value] = decodeURIComponent(cookies[i].trim()).split('=');
    if (name === encodedCookieName) {
      return value;
    }
  }
  return null;
};
