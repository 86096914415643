import React, { useState } from 'react';
import ActionButtons from './ActionButtons';
import VeneerModal from '@veneer/core/dist/esm/scripts/modal';
import ModalContent from './Content';
import { useI18n } from '@jarvis/react-portal-addons';

export const ErrorModal = ({
  title = '',
  errorMessage = '',
  actionLabel = '',
  actionCallback = () => window.location.reload(),
  dismissLabel = undefined,
  dismissCallback = undefined,
  onClose = undefined
} = {}) => {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useI18n();

  const removeModal = () => {
    if (onClose) {
      onClose();
    }

    setIsVisible(false);
  };

  const Footer = (
    <ActionButtons
      actionLabel={actionLabel || t('home.error.confirmLabel')}
      actionCallback={actionCallback}
      dismissLabel={dismissLabel}
      dismissCallback={dismissCallback}
      removeModal={removeModal}
    />
  );

  return (
    <VeneerModal
      id="user-onboarding-error-modal"
      data-testid="user-onboarding-error-modal"
      show={isVisible}
      footer={Footer}
      align="center"
    >
      <ModalContent
        title={title || t('home.error.retrieveData.title')}
        message={errorMessage || t('home.error.retrieveData.message')}
      />
    </VeneerModal>
  );
};
