import React from 'react';
import { Container, Icon } from './styles';

const ModalContent = ({ title, message }) => {
  return (
    <Container>
      <Icon size={48} />
      <h4 className="title-small">{title}</h4>
      <p className="caption">{message}</p>
    </Container>
  );
};

export default ModalContent;
