import styled from 'styled-components';
import Button from '@veneer/core/dist/esm/scripts/button';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 495px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ActionButton = styled(Button)`
  margin-left: ${(props) => (props.btntype === 'dismiss' ? '10px' : '0')};
  width: 112px;
  height: 48px;

  @media (max-width: 495px) {
    margin-left: 0;
    margin-top: ${(props) => (props.btntype === 'dismiss' ? '10px' : '0')};
  }
`;
