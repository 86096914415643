import React from 'react';
import PropTypes from 'prop-types';
import IconWarning from '@veneer/core/dist/scripts/icons/icon_warning';
import {
  Container,
  Icon,
  SubTitle,
  Title,
} from './styles';

const NoDataYet = ({ i18n, largeSize }) => (
  <Container data-testid="no-data-yet-container" largeSize={largeSize}>
    <Icon>
      <IconWarning size={24} color="colorGray7" />
    </Icon>
    <Title className="label" data-testid="no-data-yet-title">
      {i18n.title}
    </Title>
    <SubTitle className="label-small" data-testid="no-data-yet-subtitle">
      {i18n.subTitle}
    </SubTitle>
  </Container>
);

NoDataYet.defaultProps = {
  largeSize: false,
};

NoDataYet.propTypes = {
  i18n: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  }).isRequired,
  largeSize: PropTypes.bool,
};

export default NoDataYet;
