import { HTTP_STATUS_CODES } from '../constants/HttpStatusCodes';

export const checkHttpCodeShouldForceLogin = ({
  authProvider,
  httpCode
} = {}) => {
  const httpCodeListToForceLogin = [
    HTTP_STATUS_CODES.Unauthorized,
    HTTP_STATUS_CODES.Forbidden
  ];

  if (authProvider && httpCode && httpCodeListToForceLogin.includes(httpCode)) {
    authProvider.forceLogin();
  }
};
