import React from 'react';
import PropTypes from 'prop-types';
import HeaderArea from '../HeaderArea';
import { LeftArea, Title } from './styles';

const CardHeader = ({
  icon,
  title,
  actionArea,
}) => (
  <HeaderArea
    leftArea={(
      <LeftArea>
        {icon}
        <Title className="subtitle-regular">{title}</Title>
      </LeftArea>
    )}
    rightArea={actionArea}
  />
);

CardHeader.defaultProps = {
  icon: null,
  actionArea: null,
};

CardHeader.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  actionArea: PropTypes.node,
};

export default CardHeader;
