import styled from 'styled-components';
import IconMinusCircle from '@veneer/core/dist/esm/scripts/icons/icon_minus_circle';

// this color is not present in Veneer tokens
const errorColor = '#C70000';

export const Icon = styled(IconMinusCircle)`
  && {
    fill: ${errorColor};
    overflow: unset;
  }
`;

export const Container = styled.div`
  text-align: center;
`;
