import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import tokens from '@veneer/tokens';
import GenericChart from '../GenericChart';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  getPointSelected,
  getPointsAverage,
  getPointsWithoutAverage,
  getTooltipAverage,
  getTooltipGeneralAverage,
} from '../utils';

const SERIES_IDS = {
  SCANS: 0,
  AVERAGE: 1,
};

const ScanChart = ({
  chartData,
  error,
  loading,
  onRetry,
  pointStartAverage,
  selectedYear,
  i18n,
}) => {
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.xs);

  const isDeviceSupportTouch = ('ontouchstart' in window)
                                || (navigator.maxTouchPoints > 0)
                                || (navigator.msMaxTouchPoints > 0);

  const colorsDefault = useMemo(() => ((['#269EB2', '#3F3B5C'])), []);
  const colorsHighlighted = useMemo(() => ((['#2AC5E0'])), []);

  const monthMapping = useMemo(() => i18n.abbrMonths
    .reduce((acc, cur, i) => ({
      ...acc,
      [cur]: i18n.months[i],
    }), {}), [i18n.months, i18n.abbrMonths]);

  const legendTitles = useMemo(() => ({
    [SERIES_IDS.AVERAGE]: i18n.legend.average,
    [SERIES_IDS.SCANS]: i18n.legend.scans,
  }), [i18n.legend.average, i18n.legend.scans]);

  const tooltip = useMemo(() => ({
    shared: sharedTooltip,
    followPointer: sharedTooltip,
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      let pointSelected = this.point;

      if (!pointSelected) {
        // eslint-disable-next-line react/no-this-in-sfc
        pointSelected = getPointSelected(this.points);
      }

      if (!!pointSelected && pointSelected.series.userOptions?.isTooltipAverage === true) {
        return getTooltipAverage(pointSelected);
      }

      // eslint-disable-next-line react/no-this-in-sfc
      const pointsAverage = getPointsAverage(this?.points);

      // eslint-disable-next-line react/no-this-in-sfc
      return getTooltipGeneralAverage(pointsAverage, isDeviceSupportTouch) + getPointsWithoutAverage(this.points).reduce((s, point) => `${s}<br/>${legendTitles[point.series.userOptions.id]}: ${point.y}`,
        // eslint-disable-next-line react/no-this-in-sfc
        `<b>${monthMapping[this.x]}</b>`);
    },
  }), [
    isDeviceSupportTouch,
    legendTitles,
    monthMapping,
    sharedTooltip,
  ]);

  const {
    monthly,
    average,
    maxAxisY,
  } = chartData;

  const options = useMemo(() => ({
    plotOptions: {
      column: {
        borderRadiusTopLeft: average ? tokens.borderRadius2 : 0,
        borderRadiusTopRight: average ? tokens.borderRadius2 : 0,
        pointWidth: 16,
      },
      series: {
        marker: {
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null, // inherit from series
        },
      },
    },
    legend: {
      reversed: true,
    },
    yAxis: {
      min: 0,
      max: isMobile ? maxAxisY : null,
      title: {
        text: i18n.yAxis,
      },
    },
    xAxis: {
      min: 0,
      max: isMobile ? 4 : 11,
      scrollbar: {
        enabled: isMobile,
      },
      categories: i18n.abbrMonths,
    },
    series: [
      {
        id: SERIES_IDS.AVERAGE,
        type: 'line',
        name: i18n.legend.average,
        data: monthly.slice(pointStartAverage).map(val => (val === null ? val : average)),
        color: colorsDefault[1],
        zIndex: 1,
        stickyTracking: false,
        pointStart: pointStartAverage,
        selectedYear,
        isTooltipAverage: true,
        pointPlacement: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      },
      {
        id: SERIES_IDS.SCANS,
        name: i18n.legend.scans,
        data: monthly,
        type: 'column',
        color: colorsDefault[0],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsHighlighted[0],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: colorsDefault[0],
            });
          },
        },
      },
    ],
  }), [
    average,
    monthly,
    maxAxisY,
    isMobile,
    colorsDefault,
    colorsHighlighted,
    pointStartAverage,
    selectedYear,
    i18n.legend.scans,
    i18n.legend.average,
    i18n.yAxis,
    i18n.abbrMonths,
  ]);

  return (
    <GenericChart
      data-testid="usage-scan"
      error={error}
      loading={loading}
      onRetry={onRetry}
      options={options}
      tooltip={tooltip}
      i18n={{
        error: i18n.error,
        retry: i18n.retry,
      }}
    />
  );
};

ScanChart.defaultProps = {
  pointStartAverage: 0,
};

ScanChart.propTypes = {
  chartData: PropTypes.shape({
    monthly: PropTypes.arrayOf(PropTypes.number).isRequired,
    average: PropTypes.number.isRequired,
    maxAxisY: PropTypes.number,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onRetry: PropTypes.func.isRequired,
  pointStartAverage: PropTypes.number,
  selectedYear: PropTypes.number.isRequired,
  i18n: PropTypes.shape({
    legend: PropTypes.shape({
      average: PropTypes.string.isRequired,
      scans: PropTypes.string.isRequired,
    }).isRequired,
    yAxis: PropTypes.string.isRequired,
    months: PropTypes.arrayOf(PropTypes.string).isRequired,
    abbrMonths: PropTypes.arrayOf(PropTypes.string).isRequired,
    error: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
  }).isRequired,
};

export default ScanChart;
